.Main{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50vh;
}

.primary-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    margin-right: 10px;
   
  }
  
  .secondary-button {
    background-color: white;
    color: #007bff;
    border: 1px solid #007bff;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    display: flex;
    justify-content: center;
  }
  