.quiz_body {
    background-color: #cedde8;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
padding-top: 5%;
    min-height: 72vh;
}


.quizapp {
    background-color: #539aec;
    width: 550px;

    height: min-content;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 10px 10px 42px 0px rgba(212, 69, 69, 0.75);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.score-section {
    display: flex;
    font-size: 24px;
    align-items: center;
}

/* QUESTION/TIMER/LEFT SECTION */
.question-section {
    order: 1;

    width: 100%;
    position: relative;
}

.question-count {
    margin-bottom: 20px;
}

.question-count span {
    font-size: 28px;
}

.question-text {
    margin-bottom: 12px;
}

.timer-text {
    background: rgb(230, 153, 12);
    padding: 15px;
    margin-top: 20px;
    margin-right: 20px;
    border: 5px solid rgb(255, 189, 67);
    border-radius: 15px;
    text-align: center;
}

/* ANSWERS/RIGHT SECTION */
.answer-section {
    order: 2;

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.button-quiz {
    width: 100%;
    font-size: 16px;
    color: #ffffff;
    background-color: #252d4a;
    border-radius: 15px;
    display: flex;
    padding: 5px;
    justify-content: flex-start;
    align-items: center;
    border: 5px solid #234668;
    cursor: pointer;
}

.correct {
    background-color: #2f922f;
}

.incorrect {
    background-color: #ff3333;
}

button:hover {
    background-color: #555e7d;
}

button:focus {
    outline: none;
}

button svg {
    margin-right: 5px;
}

/* tracker screen  */


.question-tracker {
    display: flex;
    flex-wrap: wrap;
    width: 20%;
    margin-right: 2%;
    justify-content: center;
  }
  
  .question-number {
    background-color: #436aea;
    border-radius: 25%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px;
    font-size: 12px;
    cursor: pointer;
  }
  
  .current-question {
    background-color: #333;
    color: #fff;
  }
  
  
  /* mobile screen  */
  @media only screen and (max-width: 600px) {
    .quiz_body {
        background-color: #cedde8;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    padding-top: 5%;
    padding-left: 1%;
        min-height: 72vh;
    }
    .quizapp {
        flex-direction: column;
      }
      .question-section {
        order: 1;
        margin-bottom: 20px;
      }
      .answer-section {
        order: 2;
      }
    
    .question-tracker {
        overflow-x: auto; 
        height: 60vh;
 
    margin-bottom: 20px;
    }
    button:hover {
        background-color: #252d4a;
    }
   
    
    }