.chatbox {
    position: fixed;
    bottom: 30px;
    right: 30px;
  }
  
  .chatbox__support {
    display: flex;
    flex-direction: column;
    background: #eee;
    width: 450px;
    height: 480px;
    z-index: -1;
    opacity: 0;
    transition: all 0.5s ease-in-out;
  }
  
  .chatbox--active {
    transform: translateY(-40px);
    z-index: 1;
    opacity: 1;
  }
  
  .chatbox__button {
    text-align: right;
  }
  
  .chatbox__header {
    position: sticky;
    top: 0;
    background: orange;
    padding: 10px;
  }
  
  .chatbox__messages {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    flex-direction: column-reverse;
    padding: 10px;
  }
  
  .messages__item {
    background: orange;
    max-width: 70.6%;
    width: fit-content;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
  }
  
  .messages__item--operator {
    margin-left: auto;
  }
  
  .messages__item--visitor {
    margin-right: auto;
  }
  
  .chatbox__footer {
    position: sticky;
    bottom: 0;
    padding: 10px;
    background: #eee;
  }
  
  .chatbox__footer input {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .messages__item--typing {
    will-change: transform;
    width: auto;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 15px 20px;
    display: table;
    margin-right: auto;
    position: relative;
    animation: 2s bulge infinite ease-out;
  }
  
  .messages__item--typing::before,
  .messages__item--typing::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: -2px;
    height: 10px;
    width: 10px;
    border-radius: 50%;
  }
  
  .messages__item--typing::after {
    height: 10px;
    width: 10px;
    left: -10px;
    bottom: -10px;
  }
  
  .messages__dot {
    height: 8px;
    width: 8px;
    float: left;
    margin: 0 1px;
    background-color: #9E9EA1;
    display: block;
    border-radius: 50%;
    opacity: 0.4;
    animation: 1s blink infinite;
  }
  
  @keyframes blink {
    50% {
      opacity: 1;
    }
  }
  
  @keyframes bulge {
    50% {
      transform: scale(1.05);
    }
  }